<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ 'canteen.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        {{ 'core.done' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let canteen of canteens" (click)="dismiss(canteen)" button>
      <ion-avatar slot="start">
        <img src="assets/canteen/logos/{{ canteen.id }}.svg" onerror="this.src='assets/canteen/canteen.svg'" />
      </ion-avatar>
      <ion-label>
        {{ canteenService.getLocalizedString(canteen.name) }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
