<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ 'departure-monitor.title' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        {{ 'core.done' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let station of stations" (click)="dismiss(station)" button>
      <ion-avatar slot="start">
        <img src='assets/departure/station.svg' />
      </ion-avatar>
      <ion-label>
        {{ station.name }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
