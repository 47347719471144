import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppState } from '../store';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Observable, from, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { hasOwnProperty } from '../helper-functions';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { Device, DeviceId } from '@capacitor/device';
import { AuthenticationService } from './oauth2/authentication.service';
import { AUTHENTICATED } from './oauth2/authentication.interceptor';

const API_URL = environment.apiUrl;
const API_SERVICE = `${API_URL}/push-notification/v4`;
const API_REGISTER = `${API_SERVICE}/register`;
const API_REGISTER_PARAM = `${API_SERVICE}/register/{0}`;
const API_STATISTICS = `${API_SERVICE}/statistic/{0}`;
const API_USER = `${API_SERVICE}/user`;

export enum PushChannel {
  GENERAL = 'GENERAL',
  SPORTS = 'SPORTS',
  LIBRARY = 'LIBRARY',
  TIMETABLE = 'TIMETABLE',
  CAMPUSID = 'CAMPUSID',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  TICKET = 'TICKET',
}

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private store: Store<AppState>,
  ) { }

  public register(pushId: string, channelList?: PushChannel[]): Observable<void> {
    return forkJoin({
      deviceInfo: from(Device.getInfo()),
      uuid: from(Device.getId())
    }).pipe(switchMap((infos) => {
      const channel = [...channelList, infos.deviceInfo.operatingSystem === 'ios' ? PushChannel.IOS : PushChannel.ANDROID];
      const deviceId = infos.uuid.identifier;
      return this.http.post<void>(API_REGISTER, { deviceId, pushId, channel }, {
        context: this.authenticationService.isAuthenticated() ? AUTHENTICATED : null
      });
    }));
  }

  public unregister() {
    return from(Device.getId()).pipe(
      switchMap((uuid: DeviceId) => {
        const deviceId = uuid.identifier;
        return this.http.delete(API_REGISTER_PARAM.format(deviceId));
      })
    );
  }

  public updateStatistics(notification: PushNotificationSchema): void {
    if (!hasOwnProperty(notification.data, 'messageId')) {
      return;
    }
    const messageId = notification.data.messageId;
    from(Device.getId())
      .pipe(
        switchMap((uuid: DeviceId) => {
          const headers = {
            'Content-Type': 'application/json',
            'X-DeviceId': uuid.identifier,
          };
          return this.http.put<any>(API_STATISTICS.format(messageId), null, { headers });
        })
      )
      .subscribe();
  }

  public sendTestNotification(): Observable<void> {
    return from(Device.getId()).pipe(
      switchMap((uuid: DeviceId) => {
        const deviceId = uuid.identifier;
        return this.http.get<void>(`${API_USER}/test`, { params: { deviceId }, context: AUTHENTICATED });
      })
    );
  }
}
